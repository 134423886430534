<template>
  <div class="invite-page">
    <lottie-player
      class="gift-animation"
      src="https://res.the4can.com/deming/lottie/gift.json"
      background="transparent"
      speed="1"
      autoplay
    >
    </lottie-player>
    <h2>渠道代理</h2>
    <div v-if="userinfo && userinfo.invitecode">
      <div class="section first">
        <h3 class="title">渠道链接</h3>
        <p class="link">{{ inviteLink }}</p>
        <br />
        <div class="button-wrap">
          <van-button type="primary" @click="copyText()"
            >复制渠道链接</van-button
          >
          <van-button type="primary" to="/main/submitResource"
            >上传资源</van-button
          >
        </div>
      </div>
    </div>
    <div class="section">
      <h3 class="title">总览数据</h3>
      <div class="overview-wrap">
        <div class="overview-item">
          <p class="cur">注册人数</p>
          <p class="num">{{ overview.registerNum || "-" }}</p>
          <p class="tip">当月：{{ overview.curMonthRegisterNum || "-" }}</p>
          <p class="tip">上月：{{ overview.prevMonthRegisterNum || "-" }}</p>
        </div>
        <div class="overview-item">
          <p class="cur">订单总数</p>
          <p class="num">{{ overview.orderNum || "-" }}</p>
          <p class="tip">当月：{{ overview.curMonthOrderNum || "-" }}</p>
          <p class="tip">上月：{{ overview.prevMonthOrderNum || "-" }}</p>
        </div>
        <div class="overview-item">
          <p class="cur">订单总额</p>
          <p class="num">￥{{ overview.orderPrice || "-" }}</p>
          <p class="tip">当月：￥{{ overview.curMonthOrderPrice || "-" }}</p>
          <p class="tip">上月：￥{{ overview.prevMonthOrderPrice || "-" }}</p>
        </div>
      </div>
      <van-button type="primary" @click="goWithdraw()">前往提现</van-button>
    </div>
    <div class="section">
      <h3 class="title">最近7天数据</h3>
      <el-table :data="recentData" border>
        <el-table-column prop="date" label="日期" />
        <el-table-column prop="registerNum" label="注册人数" />
        <el-table-column prop="orderNum" label="订单数" />
        <el-table-column prop="orderPrice" label="订单总额" />
      </el-table>
    </div>
    <div class="section">
      <h3 class="title">具体规则</h3>
      <div class="rule-wrap">
        <ol>
          <li>
            分享你的渠道链接，新用户点击你的渠道链注册成功，并在网站产生付费行为，你可获得该用户付费金额一定比例的返现；
          </li>
          <li>
            返现规则如下：
            <ul>
              <li>
                总金额低于 <mark>10,000</mark> 的部分按照 20% 的比例返现；
              </li>
              <li>
                总金额超出 <mark>10,000</mark> 的部分且不大于
                <mark>50,000</mark> 的部分按照 15% 的比例返现；
              </li>
              <li>总金额超出 <mark>100,000</mark> 部分按照 10% 的比例返现；</li>
            </ul>
          </li>
          <li>返现总金额累积到<span class="em">￥10</span>，则可提现；</li>
          <li>提现申请经过核实后，14 个工作日内转账到你的支付宝；</li>
          <li>若发现有用户存在异常刷量等违规行为，我们有权判定推荐无效；</li>
          <li>最终解释权归本网站所有。</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import copy from "../utils/clipboard";
import { getHostOverviewData, getHostRecentData } from "../api/user";
export default {
  data() {
    return {
      overview: {},
      recentData: [],
    };
  },
  async created() {
    this.getOverviewData();
    this.getRecentData();
  },
  computed: {
    inviteLink() {
      if (this.userinfo && this.userinfo.invitecode)
        return `${this.DOMAIN}/#/?code=${this.userinfo.invitecode}`;
      return "";
    },
  },
  methods: {
    goWithdraw() {
      this.$router.push("/main/withdraw");
    },
    copyText() {
      if (copy(this.inviteLink)) {
        this.$toast.success("复制成功");
      } else {
        this.$toast.fail("复制失败，请稍后重试");
      }
    },
    getOverviewData() {
      getHostOverviewData().then((res) => {
        if (res.code === 0) {
          this.overview = res.data;
        }
      });
    },
    getRecentData() {
      getHostRecentData().then((res) => {
        if (res.code === 0) {
          this.recentData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.invite-page {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;

  .gift-animation {
    height: 100px;
  }

  h2 {
    font-size: 18px;
    color: #b98500;
  }

  .section {
    .link {
      padding: 5px 10px;
      color: #666;
      font-size: 12px;
      border-radius: 4px;
      display: inline-block;
      background-color: rgba($theme-color, 0.2);
    }

    .button-wrap {
      .van-button {
        margin-top: 15px;
        height: 32px;
        width: 150px;
        border-radius: 18px;
        &:last-child {
          margin-left: 20px;
        }
      }
    }
    .title {
      line-height: 1;
      font-size: 16px;
      margin-top: 30px;
      font-weight: bold;
      color: $theme-color;
      margin-bottom: 30px;
      background: url("https://res.yimiaopan.com/pan/images/title_bg.png")
        no-repeat 50% 50%;
      background-size: 180px 1px;
    }

    .overview-wrap {
      display: flex;
      justify-content: space-around;

      .overview-item {
        width: 30%;

        .title {
          font-size: 17px;
          font-weight: bold;
        }

        .num {
          font-weight: bold;
          font-size: 22px;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .tip {
          font-size: 14px;
          color: #666;
        }
      }
    }

    .el-table {
      margin: 0 auto;
    }

    .rule-wrap {
      color: #666;
      font-size: 12px;

      .em {
        font-weight: bold;
        color: #333;
      }

      ol {
        text-align: justify;
        padding-left: 20px;
        list-style: decimal;
      }

      li {
        margin-top: 6px;
      }

      ul {
        list-style: disc;
        padding-left: 10px;
      }
    }
  }
}

@media (max-width: 700px) {
  .invite-page {
    .section {
    }
  }
}
</style>
